.root {
  &:focus {
    background-color: transparent;
    text-decoration: none;
  }
  &:hover {
    background-color: transparent;
    text-decoration: none;
  }
}
