.badgeCollinson {
  display: inline-flex;
  align-items: center;
  padding: 4px 16px;
  border-radius: 8px;
  font-size: rem(18px);
  font-weight: 600;
  color: var(--white);
  background-color: var(--brand-collinson);
}
.badgeInverted {
  color: var(--brand-collinson);
  background-color: var(--bg-collinson);
}
.badgeSmall {
  padding: 0px 7px;
  text-transform: uppercase;
  font-size: rem(10.53px);
}
