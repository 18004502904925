.input {
  background-color: white;
  height: 50px;
  border-radius: 0;
  border-color: var(--mantine-color-grey-4);
  color: var(--mantine-color-grey-6);
  &::placeholder {
    color: var(--mantine-color-grey-5);
  }
}
.label {
  font-weight: 600;
}
.invalid {
  border-color: var(--mantine-color-red-6);
  border-width: 2;
  color: var(--mantine-color-dark-6);
  border-width: 2;
  &::placeholder {
    color: var(--mantine-color-gray-5);
  }
}
