.rootOutline {
  color: var(--text-default);
  border: 1px solid var(--text-default);
  &:hover {
    background-color: var(--white);
  }
  &:disabled {
    color: var(--text-grey);
  }
}
.root {
  &:disabled {
    color: var(--text-grey);
  }
}
