.root {
  --tl-line-width: 2px;
  position: fixed;
  width: 284px;
}

.drawer-button {
  background-color: var(--white);
  border: 1px solid var(--text-default);
  border-radius: 4px;
  padding: 20px 12px;
  width: auto;
}

.bullet {
  background-color: var(--white);
  border: 2px solid var(--text-default);
  top: auto;
}

.item {
  display: block;
  color: var(--text-default);
  text-decoration: none;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;

  &:is([data-current='true']) {
    background-color: var(--white);
  }

  &:not(:first-of-type) {
    margin-top: 16px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }

  [data-mantine-color-scheme='light'] & {
    --_item-border-color: var(--text-default);
  }
}

.itemTitle {
  font-weight: 400;
}

.itemBody {
  padding-top: 4px;
}

.bullet-edited {
  border-color: var(--accent-amber);
}

.bullet-blank {
  border-color: var(--grey-border);
}

.bullet-valid {
  border-color: var(--accent-green);

  svg path {
    stroke: var(--accent-green);
    stroke-width: 3;
  }
}

.bullet-invalid {
  border-color: var(--accent-red);
  background-color: var(--accent-red);
  --_bullet-bg-color: var(--accent-red);
}

.mobile-current-step {
  background-color: var(--white);
  border: 2px solid var(--grey-border);
  border-radius: 8px;
  padding: 10px 12px;
}

.mobile-icon-valid {
  background-color: var(--white);
  border: 2px solid var(--accent-green);

  svg path {
    stroke: var(--accent-green);
    stroke-width: 3;
  }
}

.mobile-icon-invalid {
  background-color: var(--accent-red);
  border: 2px solid var(--accent-red);

  svg path {
    stroke: var(--white);
  }
}

.mobile-icon-blank {
  background-color: var(--white);
  border: 2px solid var(--grey-border);
}

.mobile-icon-edited {
  background-color: var(--white);
  border: 2px solid var(--accent-amber);
}

.infoIcon {
  height: 24px;
  width: 24px;
}

.iconContainer {
  position: absolute;
  right: 12px;
  top: 10px;
}

.drawerOverlay {
  z-index: 402 !important;
}

.drawerInner {
  z-index: 403 !important;
}
