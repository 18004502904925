.skipLinkButton {
  font-size: rem(16px);
  padding: 8px 24px;
  border-radius: 6px;
  position: absolute;
  left: -9999px;
  z-index: 99999;
  top: 60px;
  background-color: var(--white);
  &:focus,
  &:focus-visible {
    left: 12px;
    outline: 1px solid rgba(32, 32, 32, 0.5);
    outline-offset: rem(2px);
    border-radius: 6px;
  }
}
