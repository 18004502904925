body {
  height: 100%;
  color: #000;
  font-weight: 400;
  background-color: #f3f2f3;
}
html {
  height: 100%;
}
#__next {
  height: 100%;
}
