.input {
  padding: rem(11px) rem(16px);
  font-size: rem(18px);
  height: rem(50px);
  background-color: var(--white);
  border-radius: rem(4px);
  color: var(--mantine-color-dark-6);
  &::placeholder {
    color: var(--mantine-color-gray-6);
  }
  &:focus {
    color: var(--mantine-color-dark-6);
  }
  &[data-invalid] {
    border-color: var(--mantine-color-red-6);
    color: var(--mantine-color-dark-6);
    &::placeholder {
      color: var(--mantine-color-gray-5);
    }
  }
}
.label {
  color: var(--text-default);
  font-weight: 600;
}
.error {
  color: var(--mantine-color-red-6);
}
