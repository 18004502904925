.title {
  margin-left: 0;
  margin-top: 4px;
  font-weight: 700;
  font-size: rem(18px);
  color: var(--text-default);
}
.legacyContent {
  font-size: rem(16px);
}
.legacyContent p {
  margin: 0px 0px 8px 0px;
  font-size: rem(16px);
}
.legacyContent p:last-child {
  margin: 0px 0px 0px 0px;
}

.legacyData {
  margin: 0px 0px 32px 0px;
  font-size: rem(18px);
  padding: 16px 24px 16px 24px;
  border-radius: 8px;
  border: 1px solid var(--grey-border);
  white-space: pre-line;
  color: var(--text-default);
}
.legacyData p {
  font-size: rem(18px);
}
.legacyOpeningTimesList {
  display: flex;
  flex-wrap: wrap;
  column-gap: 36px;
  row-gap: 24px;
  margin-block-start: 0;
  margin-block-end: 0;
}
.label {
  color: var(--text-grey);
  font-size: rem(16px);
}
.description {
  margin-left: 0;
  margin-top: 4px;
  font-size: rem(18px);
}
