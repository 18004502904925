.input {
  padding: 11px 16px;
  font-size: 18px;
  height: 50px;
  background-color: var(--mantine-color-white);
  border-radius: 4px;
  border-color: var(--mantine-color-gray-4);
  color: var(--mantine-color-dark-6);
  &::placeholder {
    color: var(--mantine-color-gray-6);
    text-transform: capitalize;
  }
  &:focus {
    border-color: var(--_input-bd-focus);
  }
  &[data-invalid] {
    border-color: var(--mantine-color-red-6);
    color: var(--mantine-color-dark-6);
    ::placeholder {
      color: var(--mantine-color-red-4);
    }
  }
}
.label {
  font-weight: 400;
  font-size: 18px;
  color: var(--mantine-color-dark-6);
}
