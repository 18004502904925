.input {
  backgroundcolor: white;
  height: rem(50px);
  border-radius: 0;
  border-color: var(--mantine-color-gray-4);
  color: var(--text-default) !important;
  &::placeholder {
    color: colors.gray[5];
    color: var(--mantine-color-gray-5);
  }
}
.label {
  font-weight: 600 !important;
}
.invalid {
  border-color: var(--mantine-color-red-6);
  border-width: rem(2px);
  color: var(--text-grey);
  &::placeholder {
    color: var(--mantine-color-gray-5);
  }
}
