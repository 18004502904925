.notification {
  width: 100%;
  padding: 8px 16px;
  background-color: var(--bg-red);
  color: var(--accent-red);
  border-left: 2px solid var(--accent-red);
}
.message {
  font-size: rem(18px);
  color: var(--accent-red);
}
