.listContainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 48px;
  row-gap: 24px;
  margin-block-start: 0;
  margin-block-end: 0;
}
.label {
  color: var(--text-grey);
  font-size: rem(16px);
}
.description {
  margin-left: 0;
  margin-top: 8px;
  font-size: rem(18px);
}
