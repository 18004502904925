.title-1 {
  font-size: 32px;
  line-height: 1.25;
  font-weight: 700;
  color: var(--text-default);

  @media (max-width: $mantine-breakpoint-sm) {
    font-size: 24px;
  }
}

.title-2 {
  font-size: 24px;
  line-height: 1.25;
  font-weight: 600;
  color: var(--text-default);

  @media (max-width: $mantine-breakpoint-sm) {
    font-size: 20px;
  }
}

.title-3 {
  font-size: 20px;
  line-height: 1.25;
  font-weight: 600;
  color: var(--text-default);

  @media (max-width: $mantine-breakpoint-sm) {
    font-size: 18px;
  }
}
