.label {
  fontsize: rem(18px);
  font-weight: 600 !important;
  color: 'black';
}
.required {
  color: var(--mantine-color-red-6);
}
.error {
  color: var(--mantine-color-red-6);
}
