.topNav {
  @mixin larger-than $mantine-breakpoint-sm {
    position: sticky;
  }
  top: 0;
  z-index: 401;
  padding: 16px 24px;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-border);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 24px;
  @mixin smaller-than $mantine-breakpoint-md {
    flex-direction: column;
  }
}
.leftSide {
  display: flex;
  gap: 6px;
  column-gap: 24px;
  @mixin smaller-than $mantine-breakpoint-md {
    width: 100%;
  }
}
.editing {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}
.iconContainer {
  display: flex;
  align-items: center;
  padding: 6px;
  justify-content: center;
  border: 1px solid var(--text-default);
  border-radius: 50%;
  width: 32px;
  height: 32px;
}
.textEditing {
  font-size: rem(14px);
}
.location {
  color: var(--text-grey);
  line-height: 22px;
  font-size: rem(18px);
}
.title {
  line-height: 40px;
  font-size: rem(32px);
}
.btnExit {
  padding: 10px 24px;
  font-size: 16px;
  border: 1px solid var(--text-default);
  @mixin smaller-than $mantine-breakpoint-md {
    width: 100%;
  }
}
.titleContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}
.btnDiscard {
  width: fit-content;
  flex-grow: 1;
  @mixin smaller-than $mantine-breakpoint-xs {
    width: 100%;
  }
}
.btnSave {
  width: fit-content;
  background-color: var(--brand-collinson);
  color: var(--white);
  flex-grow: 1;
  @mixin smaller-than $mantine-breakpoint-xs {
    flex-grow: 1;
  }
  &:disabled {
    background-color: var(--grey-mid);
    color: var(--text-grey);
  }
}
.btnContainer {
  @mixin smaller-than $mantine-breakpoint-md {
    width: 100%;
  }
}
.editedBtnContainer {
  @mixin smaller-than $mantine-breakpoint-xs {
    flex-wrap: wrap;
    width: 100%;
  }
}
.skipLinkButton {
  font-size: rem(16px);
  padding: 8px 24px;
  border-radius: 6px;
  position: absolute;
  left: -9999px;
  z-index: 99999;
  top: 60px;
  background-color: var(--white);
  &:focus,
  &:focus-visible {
    left: 12px;
    outline: 1px solid rgba(32, 32, 32, 0.5);
    outline-offset: rem(2px);
    border-radius: 6px;
  }
}
