.input {
  height: 50px;
  border-radius: 4px;
  &[data-invalid] {
    border-color: var(--mantine-color-red-6);
    color: var(--mantine-color-dark-6);
    &::placeholder {
      color: var(--mantine-color-gray-5);
    }
  }
}
.innerInput {
  height: 100%;
  font-size: 18px;
  &[data-invalid] {
    color: colors.dark[6];
    &::placeholder {
      color: var(--mantine-color-gray-5);
    }
  }
}
.label {
  color: var(--mantine-color-dark-6);
  font-weight: 600 !important;
}
.section {
  & button {
    color: #a8a8aa;
  }
  &[data-invalid] {
    color: var(--mantine-color-dark-6);
    &::placeholder {
      color: var(--mantine-color-gray-5);
    }
  }
}
